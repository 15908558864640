<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import vendorModal from "@/components/user/vendor-modal.vue";
import { vendor } from "@/config/api/vendor";
import vendorCard from "@/components/user/vendor-card.vue";
import changePassword from "@/components/user/change-password.vue";

export default {
  page: {
    title: "",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, vendorModal, vendorCard, changePassword },
  data() {
    return {
      openModal: false,
      vendorData: [],
      vendorSelected: null,
      selected_user_id: null,
      title: "Advanced Table",
      items: [
        {
          text: "Tables",
          href: "/",
        },
        {
          text: "Advanced",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25, 50, 100],
      searchValue: "",
      dataLoading: false,
      rows: 0,
      searchTimer: null, // Timer variable for delaying search
    };
  },
  watch: {
    perPage(newValue) {
      if (newValue) {
        this.getAllVendor();
      }
    },
  },
  created() {
    this.getAllVendor();
  },
  mounted() {
    this.$refs.searchInput.focus(); 
  },
  methods: {
    handleSearch() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }

      this.searchTimer = setTimeout(() => {
        this.getAllVendor();
      }, 2000); 
    },
    AddVendor() {
      this.openModal = true;
    },
    pageChange(val) {
      this.currentPage = val;
      this.getAllVendor();
    },
    getAllVendor() {
      this.vendorData = [];
      this.dataLoading = true;
      const api = vendor.getAllVendors;
      api.params = {
        page: this.currentPage,
        limit: this.perPage,
        search: this.searchValue,
      };
      this.generateAPI(api)
        .then((res) => {
          this.vendorData = res.data.vendors?.docs;
          this.rows = res.data.vendors?.totalDocs;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    deleteVendor(id) {
      this.$bvModal
        .msgBoxConfirm("Do you want to delete?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((result) => {
          if (result) {
            const api = vendor.deleteVendor;
            api.data = {
              vendorId: id,
            };
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Vendor removed successfully!", {
                  title: "Vendor",
                  variant: "success",
                  solid: true,
                });
                this.vendorData = this.vendorData.filter(
                  (item) => item._id !== id
                );
              })
              .catch(() => {
                this.$bvToast.toast("Vendor deletion failed!", {
                  title: "Vendor deletion",
                  variant: "danger",
                  solid: true,
                });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editVendor(data) {
      this.vendorSelected = data;
      this.openModal = true;
      this.vendorSelected.password = null;
    },
    removeTribeFromVendor(id) {
      const api = vendor.removeAssignedTribe;
      api.data = {
        vendorId: id,
      };
      this.generateAPI(api)
        .then((res) => {
          this.$bvToast.toast("Vendor removed successfully!", {
            title: "Vendor",
            variant: "success",
            solid: true,
          });
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    modalClosed() {
      this.openModal = false;
      this.vendorSelected = null;
    },
    dataAdded() {
      this.getAllVendor();
    },
    changePassword(id) {
      this.selected_user_id = id;
      this.$bvModal.show("password-reset");
    },
  },
};
</script>

<template>
  <Layout>
    <div class="right-align m-2">
      <b-button variant="primary" @click="AddVendor">
        <i class="fas fa-user-plus"></i><span class="pl-2">Vendor</span>
      </b-button>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row mt-4" v-if="!dataLoading">
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  ref="searchInput" 
                  type="search"
                  placeholder="Search..."
                  class="form-control form-control-sm ml-2"
                  v-model="searchValue"
                  @input="handleSearch"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div style="text-align: center; margin: 28px" v-if="dataLoading">
          <pulse-loader color="#e83e8c" />
        </div>
        <div class="row">
          <div
            v-for="data in vendorData"
            :key="data._id"
            class="col-sm-12 col-md-4"
          >
            <vendorCard
              :data="data"
              v-on:editVendor="editVendor"
              v-on:deleteVendor="deleteVendor"
              v-on:changePassword="changePassword"
              v-on:removeTribeFromVendor="removeTribeFromVendor"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="float-right">
              <ul class="pagination pagination-rounded mb-4">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @change="pageChange"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <vendorModal
      :openModal="openModal"
      v-on:hideModal="modalClosed"
      :vendorSelected="vendorSelected"
      v-on:dataAdded="dataAdded"
    />
    <changePassword
      :id="selected_user_id"
      v-on:passwordChanged="$bvModal.hide('password-reset')"
    />
  </Layout>
</template>

<style scoped>
.tribe-remove-btn {
  border: none;
  background-color: antiquewhite;
  box-shadow: -2px 2px 5px -1px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -2px 2px 5px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -2px 2px 5px -1px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  font-size: 12px;
}
</style>
