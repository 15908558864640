<template>
  <b-modal id="password-reset" size="sm" hide-footer>
    <FormulateForm :values="values" @submit="submitHandler">
      <p @click="myFunction" id="showpass" class="show-password">
        show password
      </p>

      <FormulateInput
        name="password"
        type="password"
        label="Password"
        placeholder="New password"
        validation="required|min:6"
        id="mypass"
      />
      <FormulateInput
        name="password_confirm"
        type="password"
        label="Confirm new password"
        placeholder="Confirm password"
        validation="required|confirm|min:6"
        validation-name="Confirmation"
        id="mypass2"
      />

      <FormulateInput
        class="float-right"
        type="submit"
        label="Change Password"
      />
    </FormulateForm>
  </b-modal>
</template>

<script>
import { auth } from "@/config/api/auth";
export default {
  props: ["id"],
  data() {
    return {
      values: {},
    };
  },
  methods: {
    submitHandler(val) {
      this.changePassword(val.password);
    },
    changePassword(password) {
      const api = auth.changePassword;
      api.data = {
        vendorId: this.id,
        password: password,
      };
      this.generateAPI(api).then((res) => {
        console.log(res.data);
        this.$bvToast.toast("Password reset successfully", {
          title: "Password change",
          variant: "success",
          solid: true,
        });
        this.$emit("passwordChanged");
      });
    },
    myFunction() {
      console.log("clicked");
      var x = document.getElementById("mypass");
      var y = document.getElementById("mypass2");

      if (x.type === "password") {
        x.type = "text";
      } else {
        x.type = "password";
      }
      if (y.type === "password") {
        y.type = "text";
      } else {
        y.type = "password";
      }
    },
  },
};
</script>

<style>
.show-password {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
</style>