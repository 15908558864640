var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","title-class":"font-18","hide-footer":""},on:{"hidden":function($event){return _vm.$emit('hideModal')}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('FormulateForm',{attrs:{"name":"vendor"},on:{"submit":_vm.submitHandler},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"company_name","type":"text","label":"Company name","placeholder":"Company name here","validation":"required","validationMessages":{ required: 'Company name is required.' }}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"contact_name","type":"text","label":"Contact name","placeholder":"Contact name here","validation":"required|matches:/^[a-zA-Z]+$/","validationMessages":{
            required: 'Contact name required and contain only letters.',
          }}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"aria-hidden":"true","name":"assigned_tribe","options":_vm.tribeAvailable,"help":_vm.loadingTribes
              ? 'Tribe list is loading,Please wait'
              : _vm.tribeAvailable.length
              ? ''
              : 'No tribes available',"label":"Tribe","type":"multi-select","validation":"required","placeholder":"Please select tribes","multiple":true,"taggable":true}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"license_no","type":"text","label":"License No.","placeholder":"License No. here","validation":"required","validationMessages":{ required: 'License number is required.' }}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"email","type":"email","label":"Email address","placeholder":"Email address","validation":"required|email"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"contact_number","type":"tel","label":"Contact","placeholder":"Phone number here!","validation":"required|number|max:10000000000","validation-messages":{
            max: 'max 10 digits',
          }}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"local_address","type":"text","label":"Local address","placeholder":"Local address here","validation":"required"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"country","options":_vm.countries,"placeholder":"Select a country","label":"Country","type":"select"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"city","type":"text","placeholder":"Please enter city","label":"City","validation":"required"}})],1)]),_c('div',{staticClass:"row"},[(!_vm.vendorSelected)?_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"password","type":"toggable-password","label":"Password","placeholder":"Your password","validation":_vm.vendorSelected ? null : 'required'}})],1):_vm._e()]),_c('div',{staticClass:"mt-5 bank-details-vendor"},[_c('strong',[_vm._v(" Bank Details ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"account_name","type":"text","label":"Account name","placeholder":"Account name","validation":"required"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"bank_name","type":"text","label":"Bank name","placeholder":"Bank name","validation":"required"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"iban","type":"text","label":"IBAN","placeholder":"IBAN here","validation":"required"}})],1),_c('div',{staticClass:"col-md-6"},[_c('FormulateInput',{attrs:{"name":"swift_code","type":"text","label":"Swift code","placeholder":"Swift code here","validation":"required"}})],1)])]),_c('div',{staticClass:"right-align mt-4 mb-2"},[_c('FormulateInput',{attrs:{"type":"submit","label":_vm.vendorSelected ? 'Update Vendor' : 'Add Vendor'}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }